import './style.scss';

import Router from './utils/Router';
import common from './routes/common';
// import home from './routes/home';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Specific pages */
	// home,
});

/** Load Events */
document.addEventListener('DOMContentLoaded', () => routes.loadEvents());
